import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import update from 'immutability-helper';
import { ToastContainer } from 'react-toastify';

import { settings_route, help_route, admin_route } from './routes';

import Nav from './Nav';
import NavIndex from './NavIndex';
import NavCompany from './NavCompany';
import NotFound from './NotFound';
import DeleteAccount from './DeleteAccount';
import Error from './Error';
import Settings from './Settings';
import Modal from './Modal';

import Index from './Home';

import HomeCompany from './components/HomeCompany';
import Home from './components/Home';
import Departure from './components/Departure';
import EditPage from './components/EditPage';
import CGU from './components/help/CGU';
import Accessibility from './components/help/Accessibility';
import Arrival from './components/Arrival';
import TraficCompany from './components/TraficCompany';
import MapCompany from './components/MapCompany';


function App() {
	const [error, setError] = useState(null);
	const [data, setData] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [account, setAccount] = useState(null);
	const [modal, setModal] = useState(true);

	const base_url = 'https://beta.kleme.fr/account';
	// base url egalement defini dans d'autre fichier !

	const updateData = async (key, value) => {
		try {
			const res = await fetch(base_url, {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					[key]: value,
				})
			});

			if (res.status === 413) {
				return {
					code: 413, response: {
						'error': {
							'code': 413,
							'message': '',
							'details': 'Taille de fichier trop grande.',
						}
					}
				};
			}
			const response = await res.json();
			return { code: res.status, response };
		} catch (error) {
			console.log(error);
			return {
				code: 500, response: {
					'error': {
						'code': 500,
						'message': '',
						'details': 'Une erreur s’est produite, impossible de communiquer avec le serveur.',
					}
				}
			};
		}

	};

	const handleOnChange = (element) => {
		setData(update(data, element));
	};

	const renderRoutes = (routes) =>
		routes.map((route, index) => {
			return <Route
				element={
					<route.component
						account={account}
						base_url={base_url}
						data={data}
						handleOnChange={handleOnChange}
						setAccount={setAccount}
						setModal={setModal}
						updateData={updateData}
					/>}
				exact={route.exact}
				key={index}
				path={route.id}
			/>;
		});

	if (error) {
		return <Error />;
	}

	return <BrowserRouter>
		<ToastContainer />

		<Routes>
			{/* Logged */}
			<Route path='/' element={<Nav
				data={data}
				setData={setData}
				setIsLoading={setIsLoading}
				isLoading={isLoading}
				setError={setError}
				base_url={base_url}
				setModal={setModal} />} >

				<Route path='/settings' element={<Settings data={data} />}>
					<Route index
						element={<Home
							account={account}
							base_url={base_url}
							data={data}
							handleOnChange={handleOnChange}
							setAccount={setAccount}
							setModal={setModal}
							updateData={updateData}
						/>} />
					{renderRoutes(settings_route)}
					{renderRoutes(help_route)}
					{data['isAdmin'] && renderRoutes(admin_route)}
				</Route>

				<Route path='/editpage' element={<Outlet />}>
					<Route index element={<NotFound />} />
					<Route path=':idpage'
						element={<EditPage
							account={account}
							base_url={base_url}
							data={data}
							handleOnChange={handleOnChange}
							setAccount={setAccount}
							setModal={setModal}
							updateData={updateData}
						/>} />
				</Route>

				<Route path='/del_account' element={<DeleteAccount />} />
			</Route>

			{/* Public */}
			<Route path='/' element={<NavIndex
				data={data}
				setData={setData}
				setIsLoading={setIsLoading}
				isLoading={isLoading}
				setError={setError}
				base_url={base_url}
				setModal={setModal} />} >

				<Route index element={<Index
					setError={setError}
					base_url={base_url}
				/>} />

				<Route path='/index' element={<Index
					setError={setError}
					base_url={base_url}
				/>} />
			</Route>

			{/* Company */}
			<Route path='/company' element={<NavCompany
				data={data}
				setData={setData}
				setIsLoading={setIsLoading}
				isLoading={isLoading}
				setError={setError}
				base_url={base_url}
				setModal={setModal} />} >

				<Route path=':company' element={<Outlet />}>
					<Route index element={<HomeCompany
						data={data}
						setData={setData}
						setError={setError}
						base_url={base_url}
					/>} />

					<Route path='home' element={<HomeCompany
						data={data}
						setData={setData}
						setError={setError}
						base_url={base_url}
					/>} />

					<Route path='departures/:stop' element={<Departure
						data={data}
						setData={setData}
						setError={setError}
						base_url={base_url}
					/>} />

					<Route path='arrivals/:stop' element={<Arrival
						data={data}
						setData={setData}
						setError={setError}
						base_url={base_url}
					/>} />

					<Route path='trafic' element={<TraficCompany
						data={data}
						setData={setData}
						setError={setError}
						base_url={base_url}
					/>} />

					<Route path='map' element={<MapCompany
						data={data}
						setData={setData}
						setError={setError}
						base_url={base_url}
					/>} />

					<Route path='page/:id' element={<HomeCompany
						data={data}
						setData={setData}
						setError={setError}
						base_url={base_url}
					/>} />
				</Route>
			</Route>

			{
				/*
                // /company/:company/home
                // /company/:company/map
                // /company/:company/pages
                // /company/:company/departures
                // /company/:company/arrivals
                // /company/:company/journeys

                <Route path='/home/:company/home' element={<CompanyHome
                    setError={setError}
                    base_url={base_url}
                />} />

                */
			}

			<Route path='/cgu' element={<CGU />} />
			<Route path='/acces' element={<Accessibility />} />
			<Route path='/accessibility' element={<Accessibility />} />

			<Route path='/crash' element={<Error />} />
			<Route element={<NotFound />} path='*' />
		</Routes>

		<Modal
			data={data}
			modal={modal}
			setModal={setModal}
		/>
	</BrowserRouter >;
}

export default App;