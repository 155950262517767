import React from 'react';
import { Link } from 'react-router-dom';

import key from '../../assets/img/key.svg';
import visibility from '../../assets/img/visibility.svg';
import clock from '../../assets/img/clock.svg';

function AccountAdmin({ account, setAccount }) {
	function handleOnClickAccount() {
		setAccount(account);
	}

	return <div className='box' style={{ border: '2px solid #868686' }}>
		<table>
			<tbody>
				<tr>
					<td>
						<img className='icone' src={`https://beta.kleme.fr/image?serv=Logo_lite&company=${account.name}`} />
					</td>
					<td>
						<div><b>{account.id} • {account.name}</b></div>
						<div>{account.email}</div>
					</td>
				</tr>
			</tbody>
		</table>
		{
			account.is_admin && <b className='is-block'>Compte Admin</b>
		}
		{
			account.is_beta && <b className='is-block'>Compte Beta</b>
		}
		{
			account.is_disabled && <b className='is-block'>Compte Désactivé</b>
		}
		<span className='space' />

		<div>
			<img className='svg' src={visibility} style={{ width: '20px' }} /> Visibilité : {account.visible ? 'Publique' : 'Privé'}
			<br />
			<img className='svg' src={key} style={{ width: '20px' }} /> Authentication :  {account.g_sign ? 'Google' : 'Classique'}
			<br />
			<img className='svg' src={clock} style={{ width: '20px' }} /> Dernière connexion : {account.lastest}
		</div>
		<div className='right'>
			<Link to='/settings/accounts/details' className='btn blue is-inline-flex' onClick={handleOnClickAccount} style={{ width: '105px' }} >
				<span>Détails</span>
			</Link>
		</div>
	</div>;
}

export default AccountAdmin;