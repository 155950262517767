import React, {useState} from 'react';

import { toast } from 'react-toastify';
import { toast_error } from '../styles';

import add from '../assets/img/add.svg';

function LogoUpload({ handleOnChange, updateData, type }) {
	function getColorByState(state) {
		if (state === 'success') {
			return 'var(--green-primary)';
		} else if (state === 'error') {
			return 'var(--red-primary)';
		} else {
			return '';
		}
	}
	async function handleUpload(event) {
		event.preventDefault();
		event.currentTarget.blur();

		if (file === null || name === '') {
			return;
		}

		const formData = new FormData();
		formData.append('id', name);
		formData.append('type', type);
		formData.append('logo', file);

		const res = await fetch('https://beta.kleme.fr/account', {
			method: 'post',
			body: formData
		});

		const response = await res.json();
		if (res.status === 200) {
			handleOnChange({ 'logos': { [type]: { $unshift: [name] } } });
			setName('');
			setFile(null);
			setProgress(100);
			setUploadState('success');
		} else {
			toast.error(response['error']['details'], toast_error);
			setProgress(100);
			setUploadState('error');
		}
		updateData();
	}
	async function handleSetFile(event) {
		const files = event.target.files;

		if (files.length !== 1) {
			return;
		}

		setFile(files[0]);
	}
	function handleChangeName(event) {
		setName(event.target.value);
	}

	const [name, setName] = useState('');
	const [file, setFile] = useState(null);
	const [progress, setProgress] = useState(0);
	const [uploadState, setUploadState] = useState('');

	return (
		<form className='block fluent_form left'>
			<span className='progress-bck'></span>
			<span className='progress' style={{ width: `${progress}%`, backgroundColor: getColorByState(uploadState) }}></span>
			<input
				onChange={handleChangeName}
				placeholder='Classification'
				type='text'
				value={name}
			/>
			<input
				accept='image/*'
				onChange={handleSetFile}
				type='file'
			/>
			<button className=' blue' onClick={handleUpload} type='submit'>
				<img alt='Ajouter' className='svg_white' src={add} />
				<span>Ajouter</span>
			</button>
		</form>
	);
}

export default LogoUpload;