import React from 'react';
import update from 'react-addons-update';

import search from '../../assets/img/search.svg';
import search_blue from '../../assets/img/search_blue.png';

import arrival from '../../assets/img/arrival.png';
import departure from '../../assets/img/departure.png';
import journeys from '../../assets/img/journeys.png';

import TextEditor from '../slate/Editor';
import SearchForm from '../SearchForm';

const img = {
	width: 30,
};

const ContentComponement = ({ data, component, id, setComponents, disabled }) => {
	function handleSetValue(e) {
		setComponents(components => update(components, { [id]: { 'content': { $set: e } } }));
	}

	switch (component.type) {

	case 'input':
		return <TextEditor
			disabled={disabled}
			value={component.content}
			setValue={handleSetValue}
		/>;
	case 'hr':
		return <span className='hr' />;

	case 'space':
		return <div className='espace' />;

	case 'trafic':
		return <div className='is-flex is-align-items-center'>
			<div
				className='info-block info-grey'
			>
				<div className='info-block-img'>
					<img src={`https://beta.kleme.fr/image?company=${data.account.name}&serv=${component.content}`} />
				</div>
			</div>
			<b className='ml-3'>{component.content}</b>
		</div>;

	case 'search':
		if (disabled) {
			return <div>
				<SearchForm
					data={data}
					defaultValue={component.content}
				/>
			</div>;
		}
		return <>
			<div className='sel-form p-1 is-flex'>
				<button className='left' style={{ width: 135 }}>
					<img src={journeys} style={img} />
					<b>Itinéraires</b>
				</button>
				<button className='left' style={{ width: 115 }}>
					<img src={departure} style={img} />
					<b>Départs</b>
				</button>
				<button className='left' style={{ width: 120 }}>
					<img src={arrival} style={img} />
					<b>Arrivées</b>
				</button>
			</div>

			<div className='p-3'>
				<button className='left' style={{ border: '2px solid #297CD361' }}>
					<img alt='Fermer' src={search_blue} />
					<span style={{ color: '#2674C6' }}>Rechercher une gare ou un itinéraire</span>
				</button>

				<br />

				<button className='blue' style={{ width: 150 }}>
					<img alt='Fermer' className='svg_white' src={search} />
					<span>Rechercher</span>
				</button>
			</div>
		</>;

	case 'head':
		return <div className='ml-5 pl-5'>
			<h1 className='index-title' style={{ fontSize: '3.5em' }}>{data.account.name}</h1>
			<h3 className='index-title'>{data.account.description}</h3>
		</div>;

	case 'iframe':
		if (component.content) {
			return <div className='editor-iframe'
				dangerouslySetInnerHTML={{ __html: component.content }}
			/>;
		}
		return null;

	case 'img':
		if (component.content) {
			return <div> 
				<img src={`/image?company=${data.account.name}&img=${component.content}`} className='page_img'/>
			</div>;
		}
		return null;

	case 'embed':
		if (component.content) {
			return <iframe className='embed' src={`https://mylines.fr/embed/${component.content.type}/${component.content.stop}/${data.account.name}?update=0`} />;
		}
		return null;

	default:
		return null;
	}
};

export default ContentComponement;