import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

function InfoBlock({ data, id, e, index, moveInfo, setIsMoving, i }) {
	const ref = useRef(null);
	const [{ handlerId }, drop] = useDrop({
		accept: `info${i}`,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			const clientOffset = monitor.getClientOffset();

			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveInfo(dragIndex, hoverIndex);
			setIsMoving(false);

			item.index = hoverIndex;
		},
	});
	const [{ isDragging }, drag] = useDrag({
		type: `info${i}`,
		item: () => {
			setIsMoving(true);
			return { id, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		}),
		end: () => {
			setIsMoving(false);
		},
	});

	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	return <div
		className='info-block info-grey is-grab'
		data-handler-id={handlerId}
		ref={ref}
		style={{ opacity }}
	>
		<div className='info-block-img'>
			<img src={`https://beta.kleme.fr/image?company=${data.account.name}&serv=${e}`} />
		</div>
	</div>;
}

export default InfoBlock;