import React from 'react';
import { Link } from 'react-router-dom';

import link_t from '../assets/img/link_t.png';
import visibility from '../assets/img/visibility.svg';
import invisible from '../assets/img/invisible.svg';
import discord from '../assets/img/discord.svg';

import Footer from '../Footer';

function Home({ data }) {
	return (
		<div>
			<div className='settings-head' style={{ backgroundImage: `url("https://beta.kleme.fr/image?serv=Wallpaper&company=${data['account']['name']}")` }}>
				<div>
					<table>
						<tbody>
							<tr>
								<td>
									<img className='icone' src={`https://beta.kleme.fr/image?serv=Logo_lite&company=${data['account']['name']}`} />
								</td>
								<td>
									<b>{data['account']['name']}</b>
									<br />
									{data['account']['email']}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			{/* Warnings */}

			<div className='block'>
				{
					data['account']['linked'] === true
						? <div className='green'>
							<img className='svg' src={link_t} /> <b>Votre compte est lié avec Train Empire.</b>
						</div>
						: <div className='orange is-align-items-start'>
							<img className='svg' src={link_t} />
							<div>
								<b>Votre compte n’est pas lié avec Train Empire.</b>
								<br />
                                Vous ne pourrez pas utiliser la recherche de trajet ou les Infogare.
								<br />
                                ➜ <u><Link to={'/settings/account'}>
                                    En savoir plus
								</Link></u>
							</div>
						</div>
				}
				{
					data['account']['visible'] === true
						? <div className='green'>
							<img className='svg' src={visibility} /> <b>Votre compagnie est visible et accessible à tous les utilisateurs de MyLines.</b>
						</div>

						: <div className='blue'>
							<img className='svg' src={invisible} /> <b>Votre compagnie est privée, seul vous pouvez voir votre compagnie.</b>
						</div>
				}
			</div>

			<div className='block blue'>
				<img className='svg' src={discord} />
				<div>
					<b>Besoin d’aide ?</b>
					<div>
						<a className='blue' href='http://discord.beta.kleme.fr'>
							<b>Demander à la communauté</b>
							<br />
                            Rendez vous sur le serveur discord
							<br />
                            ➜ <span className='link_blue'>discord.beta.kleme.fr</span>
						</a>
					</div>
				</div>
			</div>
			<span className='space' />
			<br />
			<Footer />
			<br /><br />

		</div>
	);
}

export default Home;