import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { toast_error } from '../styles';

import trash from '../assets/img/trash.svg';

function ImageInput({ handleOnChange, updateData, company, label, id, defined, canBeNull, isMap }) {
	function getColorByState(state) {
		if (state === 'success') {
			return 'var(--green-primary)';
		} else if (state === 'error') {
			return 'var(--red-primary)';
		} else {
			return '';
		}
	}
	async function handleDelete(event) {
		event.preventDefault();
		event.currentTarget.blur();
        
		const { code, response } = await updateData('remove_img', id);

		if (code === 200) {
			if (!isMap) {
				handleOnChange({ 'images': { [id]: { $set: false } } });
			} else {
				handleOnChange({ 'map': { 'img': { $set: false } } });
			}
			setProgress(0);
			setUploadState('ok');
		} else {
			toast.error(response['error']['details'], toast_error);
			setProgress(100);
			setUploadState('error');
		}
		setCache(Date.now());
	}
	async function handleOnUpload(event) {
		const files = event.target.files;

		if (files.length !== 1) {
			return;
		}

		const file = files[0];

		setProgress(10);

		const formData = new FormData();
		formData.append('id', id);
		formData.append('image', file);

		const res = await fetch('https://beta.kleme.fr/account', {
			method: 'post',
			body: formData
		});

		const response = await res.json();
		if (res.status === 200) {
			if (!isMap) {
				handleOnChange({ 'images': { [id]: { $set: true } } });
			} else {
				handleOnChange({ 'map': { 'img': { $set: true } } });
			}
			setProgress(100);
			setUploadState('success');
		} else {
			if (!isMap) {
				handleOnChange({ 'images': { [id]: { $set: false } } });
			} else {
				handleOnChange({ 'map': { 'img': { $set: false } } });
			}
			toast.error(response['error']['details'], toast_error);
			setProgress(100);
			setUploadState('error');
		}

		setCache(Date.now());
	}

	const [progress, setProgress] = useState(0);
	const [uploadState, setUploadState] = useState('');
	const [cache, setCache] = useState(Date.now());

	return (
		<form className='block fluent_form left'>
			{
				!defined && canBeNull
					? 'Non défini'
					: <img className='Img-company' src={`https://beta.kleme.fr/image?serv=${id}&company=${company}&t=${cache}`} />
			}

			<b className='is-block'>{label}</b>
			<span className='progress-bck'></span>
			<span className='progress' style={{ width: `${progress}%`, backgroundColor: getColorByState(uploadState) }}></span>
			<input
				accept='image/*'
				onChange={handleOnUpload}
				type='file'
			/>
			{
				defined &&
                <button className=' red' onClick={handleDelete} type='submit'>
                	<img alt='Supprimer' className='svg_white' src={trash} />
                	<span>Supprimer</span>
                </button>
			}
		</form>
	);
}

export default ImageInput;