import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import key from '../../assets/img/key.svg';
import visibility from '../../assets/img/visibility.svg';
import clock from '../../assets/img/clock.svg';

import arrow_left from '../../assets/img/arrow-left.svg';

function AccountDetails({ account }) {

	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(true);

	return <div>
		<Link to='/settings/accounts' className='btn fluent_btn is-inline-flex' style={{ width: '105px' }} >
			<img className='svg' src={arrow_left} /> <span>Retour</span>
		</Link>
		{
			!account
				? <div className='orange'>Aucun compte n’est séléctionné.</div>
				: <div>
					<div className='box' style={{ border: '2px solid #868686' }}>
						<table>
							<tbody>
								<tr>
									<td>
										<img className='icone' src={`https://beta.kleme.fr/image?serv=Logo_lite&company=${account.name}`} />
									</td>
									<td>
										<div><b>{account.id} • {account.name}</b></div>
										<div>{account.email}</div>
									</td>
								</tr>
							</tbody>
						</table>
						<span className='space' />

						<div>
							<img className='svg' src={visibility} style={{ width: '20px' }} /> Visibilité : {account.visible ? 'Publique' : 'Privé'}
							<br />
							<img className='svg' src={key} style={{ width: '20px' }} /> Authentication :  {account.g_sign ? 'Google' : 'Classique'}
							<br />
							<img className='svg' src={clock} style={{ width: '20px' }} /> Dernière connexion : {account.lastest}
						</div>
					</div>

					{
						isLoading
							? <div className='box' style={{ border: '2px solid #868686' }}>
								<br />
								<div className='progress-bck'></div>
								<div className='progress indeterminate'></div>
								<br />
							</div>
							: <p>test</p>
					}


				</div>
		}

	</div>;
}

export default AccountDetails;