import React from 'react';
import { Link } from 'react-router-dom';

import packageJson from '../package.json';

import logo from './assets/img/logo/Logo.png';
import discord from './assets/img/discord.svg';
import mail from './assets/img/mail.svg';

function Footer() {
	return <div className='footer m-5'>
		<div className='footer-flex'>
			<div className='column' style={{ width: 500 }}>
				<img className='logo' src={logo} />
				<br />
				<span>
                    MyLines 2021 - {new Date().getFullYear()} • Version v{packageJson.version} <br />
                    Made with 💖 / In Tartiflette we trust
				</span>
				<br />
				<a className='mini_btn' href='http://discord.beta.kleme.fr'>
					<img alt='discord' className='svg' src={discord} />
				</a>
				<a className='mini_btn' href='mailto:clementf78@gmail.com'>
					<img alt='mail' className='svg' src={mail} />
				</a>
			</div>
			<div className='column'>
				<h3>Besoin d’aide ?</h3>
				<a className='link_blue' href='https://mylines.betteruptime.com/'> Status </a> <br />
				<a className='link_blue' href='http://discord.beta.kleme.fr'> Demander à la communauté </a> <br />
				<a className='link_blue' href='http://beta.kleme.fr/embed/doc'> Documentation MyLines Embed </a> <br />
			</div>

			<div className='column'>
				<h3>Remerciements</h3>
                SSHI#1243       <br />
                thithis#1523    <br />
                Tibo#4872       <br />
                Auguste#1143
			</div>

			<div className='column'>
				<h3>À propos</h3>

				<Link to='/cgu' className='link_blue' > Conditions générales </Link> <br />
				<a className='link_blue' href='https://icones8.fr/' rel='noreferrer' target='_blank'> Icones par Icones8</a> <br />
				<a className='link_blue' href='https://animista.net/' rel='noreferrer' target='_blank'> Animations CSS par Animista <br />
				</a><a className='link_blue' href='https://train-empire.com/fr/' rel='noreferrer' target='_blank'> Permis par Train-Empire </a>
			</div>

			<div className='column'>
				<h3>Accessibilité</h3>

				<Link to='/acces' className='link_blue'> Options d’accessibilité </Link> <br />
				<a className='link_blue' href='http://luciole-vision.com/' rel='noreferrer' target='_blank'> Police Luciole</a> <br />
			</div>
		</div>
		<br />
	</div>;
}

export default Footer;