import React, {useState} from 'react';

import { toast } from 'react-toastify';
import { toast_error } from '../styles';

import trash from '../assets/img/trash.svg';

function LogoInput({ handleOnChange, updateData, company, label, id, index, type }) {
	function getColorByState(state) {
		if (state === 'success') {
			return 'var(--green-primary)';
		} else if (state === 'error') {
			return 'var(--red-primary)';
		} else {
			return '';
		}
	}
	async function handleDelete(event) {
		event.preventDefault();
		event.currentTarget.blur();
        
		setUploadState('doing');
		const { code, response } = await updateData(`remove_${type}`, id);

		if (code === 200) {
			handleOnChange({ 'logos': { [type]: { $splice: [[index, 1]] } } });
			setUploadState('ok');
			// remove
		} else {
			toast.error(response['error']['details'], toast_error);
			setUploadState('error');
		}
		setCache(Date.now());
	}

	const [uploadState, setUploadState] = useState('');
	const [cache, setCache] = useState(Date.now());

	return (
		<form className='block fluent_form left'>
			<img className='Img-company' src={`https://beta.kleme.fr/image?serv=${id}&company=${company}&t=${cache}`} style={{maxHeight: '75px'}} />

			<b className='is-block'>{label}</b>
			<span className='progress-bck'></span>
			{
				uploadState == 'doing'
					? <span className='progress indeterminate' style={{backgroundColor: getColorByState(uploadState) }}></span>
					: <span className='progress' style={{width: uploadState == 'error' && '100%', backgroundColor: getColorByState(uploadState) }}></span>

			}

			<button className=' red' onClick={handleDelete} type='submit'>
				<img alt='Supprimer' className='svg_white' src={trash} />
				<span style={{ top: '-5px' }}>Supprimer</span>
			</button>
		</form>
	);
}

export default LogoInput;